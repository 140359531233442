exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-basketball-courts-basketball-court-dimensions-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/basketball/courts/basketball-court-dimensions.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-basketball-courts-basketball-court-dimensions-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-rules-football-red-and-yellow-cards-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/en-gb/soccer/rules/football-red-and-yellow-cards.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-rules-football-red-and-yellow-cards-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-rules-how-long-is-a-football-game-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/en-gb/soccer/rules/how-long-is-a-football-game.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-rules-how-long-is-a-football-game-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-rules-how-many-football-players-on-the-pitch-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/en-gb/soccer/rules/how-many-football-players-on-the-pitch.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-rules-how-many-football-players-on-the-pitch-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-rules-is-football-a-contact-sport-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/en-gb/soccer/rules/is-football-a-contact-sport.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-rules-is-football-a-contact-sport-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-rules-what-is-a-corner-kick-in-football-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/en-gb/soccer/rules/what-is-a-corner-kick-in-football.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-rules-what-is-a-corner-kick-in-football-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-rules-what-is-a-goal-kick-in-football-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/en-gb/soccer/rules/what-is-a-goal-kick-in-football.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-rules-what-is-a-goal-kick-in-football-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-rules-what-is-advantage-in-football-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/en-gb/soccer/rules/what-is-advantage-in-football.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-rules-what-is-advantage-in-football-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-terms-what-does-man-on-mean-in-football-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/en-gb/soccer/terms/what-does-man-on-mean-in-football.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-terms-what-does-man-on-mean-in-football-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-terms-what-does-switch-mean-in-football-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/en-gb/soccer/terms/what-does-switch-mean-in-football.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-terms-what-does-switch-mean-in-football-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-terms-what-is-a-cap-in-football-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/en-gb/soccer/terms/what-is-a-cap-in-football.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-terms-what-is-a-cap-in-football-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-terms-what-is-a-hat-trick-in-football-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/en-gb/soccer/terms/what-is-a-hat-trick-in-football.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-terms-what-is-a-hat-trick-in-football-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-terms-what-is-an-assist-in-football-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/en-gb/soccer/terms/what-is-an-assist-in-football.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-terms-what-is-an-assist-in-football-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-terms-what-is-nutmegging-in-football-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/en-gb/soccer/terms/what-is-nutmegging-in-football.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-en-gb-soccer-terms-what-is-nutmegging-in-football-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-kids-games-physical-games-capture-the-flag-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/kids-games/physical-games/capture-the-flag.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-kids-games-physical-games-capture-the-flag-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-kids-games-physical-games-freeze-tag-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/kids-games/physical-games/freeze-tag.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-kids-games-physical-games-freeze-tag-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-kids-games-physical-games-hopscotch-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/kids-games/physical-games/hopscotch.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-kids-games-physical-games-hopscotch-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-pickleball-courts-pickleball-court-dimensions-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/pickleball/courts/pickleball-court-dimensions.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-pickleball-courts-pickleball-court-dimensions-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-pickleball-rules-pickleball-etiquette-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/pickleball/rules/pickleball-etiquette.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-pickleball-rules-pickleball-etiquette-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-pickleball-terms-what-does-stacking-mean-in-pickleball-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/pickleball/terms/what-does-stacking-mean-in-pickleball.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-pickleball-terms-what-does-stacking-mean-in-pickleball-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-competitions-english-premier-league-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/competitions/english-premier-league.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-competitions-english-premier-league-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-competitions-major-league-soccer-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/competitions/major-league-soccer.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-competitions-major-league-soccer-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-competitions-soccer-promotion-and-relegation-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/competitions/soccer-promotion-and-relegation.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-competitions-soccer-promotion-and-relegation-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-competitions-when-is-soccer-season-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/competitions/when-is-soccer-season.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-competitions-when-is-soccer-season-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-equipment-cut-soccer-socks-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/equipment/cut-soccer-socks.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-equipment-cut-soccer-socks-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-fields-home-advantage-in-soccer-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/fields/home-advantage-in-soccer.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-fields-home-advantage-in-soccer-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-fields-soccer-field-dimensions-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/fields/soccer-field-dimensions.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-fields-soccer-field-dimensions-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-fields-soccer-field-markings-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/fields/soccer-field-markings.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-fields-soccer-field-markings-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-fields-soccer-goal-size-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/fields/soccer-goal-size.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-fields-soccer-goal-size-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-fields-why-is-a-soccer-field-called-a-pitch-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/fields/why-is-a-soccer-field-called-a-pitch.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-fields-why-is-a-soccer-field-called-a-pitch-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-formations-soccer-4-4-2-formation-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/formations/soccer-4-4-2-formation.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-formations-soccer-4-4-2-formation-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-players-how-much-does-a-soccer-player-run-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/players/how-much-does-a-soccer-player-run.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-players-how-much-does-a-soccer-player-run-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-players-when-do-soccer-players-retire-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/players/when-do-soccer-players-retire.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-players-when-do-soccer-players-retire-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-positions-center-back-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/positions/center-back.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-positions-center-back-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-positions-false-nine-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/positions/false-nine.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-positions-false-nine-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-positions-fullback-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/positions/fullback.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-positions-fullback-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-positions-goalkeeper-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/positions/goalkeeper.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-positions-goalkeeper-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-rules-how-long-is-a-soccer-game-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/rules/how-long-is-a-soccer-game.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-rules-how-long-is-a-soccer-game-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-rules-how-many-soccer-players-on-the-field-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/rules/how-many-soccer-players-on-the-field.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-rules-how-many-soccer-players-on-the-field-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-rules-is-soccer-a-contact-sport-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/rules/is-soccer-a-contact-sport.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-rules-is-soccer-a-contact-sport-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-rules-soccer-red-and-yellow-cards-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/rules/soccer-red-and-yellow-cards.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-rules-soccer-red-and-yellow-cards-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-rules-what-is-a-corner-kick-in-soccer-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/rules/what-is-a-corner-kick-in-soccer.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-rules-what-is-a-corner-kick-in-soccer-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-rules-what-is-a-goal-kick-in-soccer-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/rules/what-is-a-goal-kick-in-soccer.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-rules-what-is-a-goal-kick-in-soccer-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-rules-what-is-advantage-in-soccer-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/rules/what-is-advantage-in-soccer.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-rules-what-is-advantage-in-soccer-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-terms-soccer-abbreviations-explained-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/terms/soccer-abbreviations-explained.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-terms-soccer-abbreviations-explained-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-terms-what-does-man-on-mean-in-soccer-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/terms/what-does-man-on-mean-in-soccer.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-terms-what-does-man-on-mean-in-soccer-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-terms-what-does-switch-mean-in-soccer-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/terms/what-does-switch-mean-in-soccer.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-terms-what-does-switch-mean-in-soccer-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-terms-what-is-a-cap-in-soccer-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/terms/what-is-a-cap-in-soccer.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-terms-what-is-a-cap-in-soccer-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-terms-what-is-a-hat-trick-in-soccer-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/terms/what-is-a-hat-trick-in-soccer.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-terms-what-is-a-hat-trick-in-soccer-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-terms-what-is-an-assist-in-soccer-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/terms/what-is-an-assist-in-soccer.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-terms-what-is-an-assist-in-soccer-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-terms-what-is-nutmegging-in-soccer-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/soccer/terms/what-is-nutmegging-in-soccer.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-soccer-terms-what-is-nutmegging-in-soccer-mdx" */),
  "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-volleyball-courts-volleyball-court-dimensions-mdx": () => import("./../../../src/templates/blog-template/blog-template.tsx?__contentFilePath=/opt/build/repo/content/volleyball/courts/volleyball-court-dimensions.mdx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx-content-file-path-opt-build-repo-content-volleyball-courts-volleyball-court-dimensions-mdx" */),
  "component---src-templates-landing-page-template-landing-page-template-tsx": () => import("./../../../src/templates/landing-page-template/landing-page-template.tsx" /* webpackChunkName: "component---src-templates-landing-page-template-landing-page-template-tsx" */),
  "component---src-templates-sub-category-template-sub-category-template-tsx": () => import("./../../../src/templates/sub-category-template/sub-category-template.tsx" /* webpackChunkName: "component---src-templates-sub-category-template-sub-category-template-tsx" */),
  "slice---src-components-global-global-footer-global-footer-tsx": () => import("./../../../src/components/global/global-footer/global-footer.tsx" /* webpackChunkName: "slice---src-components-global-global-footer-global-footer-tsx" */),
  "slice---src-components-global-global-header-global-header-tsx": () => import("./../../../src/components/global/global-header/global-header.tsx" /* webpackChunkName: "slice---src-components-global-global-header-global-header-tsx" */)
}

